import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Deadlifts 4×8 to an 8RM`}</p>
    <p>{`then,`}</p>
    <p>{`4 Rounds for time of:`}</p>
    <p>{`100ft Bear Crawl`}</p>
    <p>{`20-SDHP’s (95/65)(RX+ 115/75)`}</p>
    <p>{`100ft Burpee Broad Jump`}</p>
    <p>{`20-Deadlifts (95/65)(RX+ 115/75)`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`The Ville will reopen on June 1st at 6:00am!  Check out the
Schedule page for an updated list of class times.  We hope to get back
to our normal class schedule asap and will keep you updated on any
changes.`}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      